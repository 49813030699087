import React, { FC, useCallback, useState } from "react"
import { IOrder } from "../../app/reducers/ordersReducer"
import { Modal } from "../../features/Modal/Modal"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"

export type RecodeKatren = {
  GoodName: string
  GoodCode: string
  nmId: number
  sku: string
  count: number
}[]

type FormKatrenOrderProps = {
  orders?: IOrder[]
}

type RecodeForOrderkatren = {
  GoodName: string
  GoodCode: string
  Quantity: number
}

type MissOrderkatren = {
  GoodCode: string
  nmId: number
}

const getOrdersForKatren = (
  recode: RecodeKatren,
  orders: IOrder[],
): {
  recodeForOrderkatren: RecodeForOrderkatren[]
  missOrders: MissOrderkatren[]
} => {
  const missOrders: MissOrderkatren[] = []
  const recodeForOrderkatrenMap = new Map<string, RecodeForOrderkatren>()
  orders.forEach((order) => {
    const recodeItem = recode.find((item) => item.nmId === order.nmId)
    if (recodeItem?.GoodCode) {
      if (recodeForOrderkatrenMap.has(recodeItem.GoodCode)) {
        const recodeForOrderkatrenItem = recodeForOrderkatrenMap.get(
          recodeItem.GoodCode,
        )
        if (recodeForOrderkatrenItem) {
          recodeForOrderkatrenItem.Quantity += Number(recodeItem.count) || 0
        } else {
          recodeForOrderkatrenMap.set(recodeItem.GoodCode, {
            GoodName: recodeItem.GoodName,
            GoodCode: recodeItem.GoodCode,
            Quantity: Number(recodeItem.count) || 0,
          })
        }
      } else {
        recodeForOrderkatrenMap.set(recodeItem.GoodCode, {
          GoodName: recodeItem.GoodName,
          GoodCode: recodeItem.GoodCode,
          Quantity: Number(recodeItem.count) || 0,
        })
      }
    } else {
      missOrders.push({ nmId: order.nmId, GoodCode: order.article })
    }
  })

  return {
    recodeForOrderkatren: Array.from(recodeForOrderkatrenMap.values()),
    missOrders,
  }
}

const downloadRecode = (data: RecodeForOrderkatren[]) => {
  const text =
    "GoodName;GoodCode;Quantity\n" +
    data.map((el) => `${el.GoodName};${el.GoodCode};${el.Quantity}`).join("\n")
  const blob = new Blob([text], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = "order_import.csv"
  link.click()
}

export const FormKatrenOrder: FC<FormKatrenOrderProps> = ({ orders }) => {
  const [isOpenModalFormOrders, setIsOpenModalFormOrders] = useState(false)
  const [missOrders, setMissOrders] = useState<MissOrderkatren[]>([])
  const [orderskatren, setOrderskatren] = useState<RecodeForOrderkatren[]>([])

  const onClickFormOrderKatren = useCallback(() => {
    BaseApi.loadRecodeKatren().then(
      ({ items, success }: { items: RecodeKatren; success: boolean }) => {
        if (success) {
          if (orders?.length) {
            const { recodeForOrderkatren, missOrders } = getOrdersForKatren(
              items || [],
              orders || [],
            )
            setOrderskatren(recodeForOrderkatren)
            setMissOrders(missOrders)
            setIsOpenModalFormOrders(true)
          } else {
            NotificationService.showNotification({
              message: "Перекодировка не найдена",
              level: "error",
            })
          }
        } else {
          NotificationService.showNotification({
            message: "Ошибка при загрузке перекодировки",
            level: "error",
          })
        }
      },
    )
  }, [orders])

  return (
    <div>
      <button disabled={!orders} onClick={onClickFormOrderKatren}>
        Сформировать файл для заказа Katren
      </button>

      <Modal
        isOpen={isOpenModalFormOrders}
        onClose={() => {
          setIsOpenModalFormOrders(false)
        }}
        header={"Сформированный файл для заказа Katren"}
        onOk={() => {
          downloadRecode(orderskatren)
        }}
        content={
          <ModalOrderList
            orderskatren={orderskatren || []}
            missOrderkatren={missOrders || []}
          />
        }
        size="l"
        okContent="Скачать"
      />
    </div>
  )
}

type ModalOrderListProps = {
  orderskatren: RecodeForOrderkatren[]
  missOrderkatren: MissOrderkatren[]
}
export const ModalOrderList = ({ missOrderkatren }: ModalOrderListProps) => {
  return (
    <div>
      <label htmlFor="miss-order-katren">
        Артикулы, которые не найдены в перекодировке
      </label>
      <table id="miss-order-katren">
        <thead>
          <tr>
            <th>Артикул продавца</th>
            <th>Артикул WB</th>
          </tr>
        </thead>
        <tbody>
          {missOrderkatren?.map((el, i) => {
            return (
              <tr key={"order-list-" + i}>
                <td>{el.GoodCode}</td>
                <td>{el.nmId}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
