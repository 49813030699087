import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { SysApi } from "./sysapi"
import { PriceType } from "../app/reducers/settingsReducer"
import { RecodeMetroType } from "../components/UpdateStoksMetro/types"
import { PodrOrdersType } from "../app/reducers/updateStoksPodruzhka"
import { RecodeKatren } from "../components/FormKatrenOrder/FormKatrenOrder"

export class BaseApi extends SysApi {
  // static register(data: { username: string; password: string }) {
  //   return axios.post(this.baseUrl + "register", data, {
  //     headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Credentials": "true",
  //       "Access-Control-Request-Method": "POST",
  //       // "Content-Type": "text/html",
  //     },
  //   })
  // }

  static testProtect() {
    return this.get(this.baseUrl + "protected")
  }

  static serachByVendorCode(vendorCodes: string[]) {
    return this.post(this.baseUrl + "api/serachByVendorCode", {
      vendorCodes,
    }).then((resp) => resp.data)
  }

  static uploadXlsxFile(data: FormData) {
    return this.post(this.baseUrl + "api/uploadKtrXslx", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
  static uploadXslxForStoks(data: FormData) {
    return this.post(this.baseUrl + "api/uploadXslxForStoks", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
  static deleteXlsxFile() {
    return this.delete(this.baseUrl + "api/deleteKtrXslx")
  }
  static deleteStockXlsxFile() {
    return this.delete(this.baseUrl + "api/deleteXslxForStoks")
  }
  static isKtrXslxExists() {
    return this.get(this.baseUrl + "api/ktrXslxExists")
  }

  static formCards() {
    return this.get(this.baseUrl + "api/formCards")
  }

  // static uploadStoks() {
  //   return this.get(this.baseUrl + "api/uploadStoks")
  // }
  static uploadState() {
    return this.get(this.baseUrl + "api/uploadState", undefined, false)
  }

  static updateStoksState() {
    return this.get(this.baseUrl + "api/updateStoksState", undefined, false)
  }

  static updateStoks(warehouseId: number | string) {
    return this.post(this.baseUrl + "api/updateStoks", {
      warehouseId,
    })
  }
  static downloadRecodes() {
    return this.get(this.baseUrl + "recode/recoding.csv")
  }

  static async uploadRecodesFile(data: FormData) {
    const response = await this.post(
      this.baseUrl + "api/uploadRecodingForm",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )

    return response
  }
  static updatePrices = () => {
    return this.get(this.baseUrl + "api/updatePrices")
  }

  static getNewOrders = () => {
    return this.get(this.baseUrl + "api/orders/new")
  }

  static getOrdersState = () => {
    return this.get(this.baseUrl + "api/ordersState")
  }

  static updateSupplies = () => {
    return this.get(this.baseUrl + "api/updateSupplies")
  }

  static addOrdersToSupplies = (supplyId: number, orderIds: number[]) => {
    return this.post(this.baseUrl + "api/addOrdersToSupplies", {
      supplyId,
      orderIds,
    })
  }
  static createNewSuppliesAndAddOrders = (name: string, orderIds: number[]) => {
    return this.post(this.baseUrl + "api/createNewSuppliesAndAddOrders", {
      name,
      orderIds,
    })
  }
  static getSuppliesWithOrders = (supplyId: string) => {
    return this.post(this.baseUrl + "api/getSuppliesWithOrders", {
      supplyId,
    })
  }
  static formOrderListForKatren = (supplyId: string) => {
    return this.post(this.baseUrl + "api/formOrderListForKatren", {
      supplyId,
    })
  }

  static downloadFormOrderListForKatren() {
    return this.get(this.baseUrl + "recode/ordersToKtrn.xlsx")
  }

  static updateStoksICONState() {
    return this.get(this.baseUrl + "api/icon/StoksState", undefined, false)
  }

  static updatePricesICON(cookie: string) {
    return this.post(this.baseUrl + "api/icon/updatePrices", { cookie })
  }

  static settings() {
    return this.get(this.baseUrl + "api/settings")
  }

  static updatePriceParams(shop: string, price: PriceType) {
    return this.post(this.baseUrl + "api/updateParamPrice", { shop, price })
  }

  static katrenUpdateStoksState() {
    return this.get(
      this.baseUrl + "api/katrenUpdateStoksState",
      undefined,
      false,
    )
  }

  static katrenUpdateStoks(
    selectedStorageId: string,
    updatePrices: boolean,
    updateStocks: boolean,
  ) {
    return this.post(
      this.baseUrl + "api/katrenUpdateStoks",
      { selectedStorageId, updatePrices, updateStocks },
      undefined,
      true,
    )
  }

  static katrenUpdateStoksForOzon(
    selectedStorageId: string,
    updatePrices: boolean,
    updateStocks: boolean,
  ) {
    return this.post(
      this.baseUrl + "api/ozon/katrenUpdateStoks",
      { selectedStorageId, updatePrices, updateStocks },
      undefined,
      true,
    )
  }

  static uploadDataFromKatren(
    data: { name: string; amount: number; price: number }[],
  ) {
    return this.post(
      this.baseUrl + "api/uploadDataFromKatren",
      { rows: data },
      undefined,
      true,
    )
  }

  static deleteCurrentDataKatren() {
    return this.delete(
      this.baseUrl + "api/deleteCurrentDataKatren",
      undefined,
      true,
    )
  }

  static deleteCurrentDataKatrenForOzon() {
    return this.delete(
      this.baseUrl + "api/ozon/deleteCurrentDataKatren",
      undefined,
      true,
    )
  }

  static uploadCurrentDataKatren(data: FormData) {
    return this.post(
      this.baseUrl + "api/uploadCurrentDataKatren",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      true,
    )
  }

  static uploadCurrentDataKatrenForOzon(data: FormData) {
    return this.post(
      this.baseUrl + "api/ozon/uploadCurrentDataKatren",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      true,
    )
  }

  static deleteDataFromKatren() {
    return this.delete(
      this.baseUrl + "api/deleteDataFromKatren",
      undefined,
      true,
    )
  }
  static podruzhkaUpdateStoks(
    selectedStorageId: string,
    updatePrices: boolean,
    updateStocks: boolean,
  ) {
    return this.post(
      this.baseUrl + "api/podruzhka/update",
      {
        selectedStorageId,
        updatePrices,
        updateStocks,
      },
      undefined,
      true,
    )
  }

  static podruzhkaUpdateStoksState() {
    return this.get(this.baseUrl + "api/podruzhka/state", undefined, true)
  }

  static deleteCurrentDataPodruzhka() {
    return this.delete(
      this.baseUrl + "api/podruzhka/delete/data",
      undefined,
      true,
    )
  }

  static uploadCurrentDataPodruzhka(data: any) {
    return this.post(
      this.baseUrl + "api/podruzhka/upload/data",
      { rows: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      true,
    )
  }

  static podruzhkaOrders() {
    return this.get<{ data: PodrOrdersType; success: boolean }>(
      this.baseUrl + "api/podruzhka/orders",
      undefined,
      true,
    )
  }

  static getSuppliesPodrOrdersInfo = <
    T = {
      success: boolean
      data: {
        orders: { productId: string; quantity: number }[]
        errArticles: string[]
      }
    },
  >(
    supplyId: string,
  ): Promise<AxiosResponse<T>> => {
    return this.post<T>(this.baseUrl + "api/podruzhka/supply/orders", {
      supplyId,
    })
  }

  static metroUpdateStoks(
    selectedStorageId: string,
    updatePrices: boolean,
    updateStocks: boolean,
  ) {
    return this.post(
      this.baseUrl + "api/metro/update",
      {
        selectedStorageId,
        updatePrices,
        updateStocks,
      },
      undefined,
      true,
    )
  }

  static metroUpdateStoksState() {
    return this.get(this.baseUrl + "api/metro/state", undefined, true)
  }

  static deleteRecodeMetro() {
    return this.delete(
      this.baseUrl + "api/metro/delete/recode",
      undefined,
      true,
    )
  }

  static uploadRecodeMetro(data: RecodeMetroType[]) {
    return this.post(
      this.baseUrl + "api/metro/upload/recode",
      { rows: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      true,
    )
  }

  static loadRecodeKatren(): Promise<{
    items: RecodeKatren
    success: boolean
  }> {
    return this.get<{ items: RecodeKatren; success: boolean }>(
      this.baseUrl + "api/katren/recode",
      undefined,
      true,
    ).then((resp) => resp.data)
  }
}

export default BaseApi
